@import url("https://fonts.googleapis.com/css?family=Public+Sans:300,400,500,600,700,800&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.left-menu .ant-menu-item-selected {
  background-color: #2192ff !important;
  color: white !important;
}

.round-header-btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  transition: background-color 250ms ease-out;
}

.round-header-btn:hover {
  border: none;
  color: #1e1e1e;
  background-color: rgba(0, 0, 0, 0.06);
}
