/* Styling of map */
#map{
    position: absolute;
    top: 70px;
    bottom: 5px;
    width: 99.3%;
  }
  
  /* Styling of Popup */
  .custom-popup .leaflet-popup-content-wrapper {
    background:#292b2c;/*#2c3e50;*/
    color:#fff;
    font-size:12px;
    line-height:24px;
    border-radius: 0px;
  }
  .custom-popup .leaflet-popup-content-wrapper a {
    color:rgba(255,255,255,0.1);
  }
  .custom-popup .leaflet-popup-tip-container {
    width:30px;
    height:15px;
  }
  .custom-popup .leaflet-popup-tip {
    background: transparent;
    border: none;
    box-shadow: none;
  }
  
  /* Styling of link (in popup) */
  .custom-popup a:link {
    color: #fff;
  }
  .custom-popup a:visited {
    color: #fff;
  }

  /* Styling of Marker Clusters */
  
  .marker-cluster-small {
    background-color: rgba(232, 76, 230, 0.6);
  }
  .marker-cluster-small div {
    background-color: rgba(232, 76, 230, 0.6);
  }
  .marker-cluster-medium {
    background-color: rgba(232, 76, 230, 0.6);
  }
  .marker-cluster-medium div {
    background-color: rgba(232, 76, 230, 0.6);
  }
  .marker-cluster-large {
    background-color: rgba(232, 76, 230, 0.6);
  }
  .marker-cluster-large div {
    background-color: rgba(232, 76, 230, 0.6);
  }