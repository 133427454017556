/* Styling of map legend */
.info.legend {
    background-color: white;
  }
  
  .legend {
    border: 2.5px solid black;
    font-weight: bold;
    color:#292b2c;/* blue;*/
    text-align: left;
    width: 180px;
    height: 320px;
    line-height: 18px;
    background: white;
    opacity: 1;
  }
  
  .legend i {
    width: 100px;
    height: 50px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
  }
  
  /* Styling of color icon in map legend */
  .legend .Circle1 {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-top: 8px;
    margin-left: 4px;
  }

  /* Styling of white circle inside Circle1 in map legend */
  .legend .Circle2 {
    background: #ffffff;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    top: 50%;
    left: 50%;
    margin: 17px 0px 0px -28px;
  }

  /* Styling for circle icon for marker Pop-Up matching the legend color code */
  i {
    width: 20px;
    height: 20px;
    float: right;
    margin-right: 100px;
    opacity: 0.7;
  }

  .Circle1 {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-top: 1px;
    margin-left: 1px;
  }

  /* .Circle2 {
    background: #ffffff;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    top: 50%;
    left: 50%;
    margin: 17px 0px 0px -28px;
  } */